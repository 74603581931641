
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pass: {
      type: Object,
      required: true
    }
  },
  computed: {
    getName () {
      return this.pass.Name.split(':')[0]
    },
    getDescription () {
      const description = this.pass.Dic.Description.split(this.getName)[1].trim()
      return description.charAt(0).toUpperCase() + description.slice(1)
    },
    getLastUpdate () {
      return this.pass.Dic.LastUpdated
    }
  }
})
