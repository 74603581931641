import { createApp } from 'vue'
import App from './App.vue'

import '@/styles/main.scss'
import 'tailwindcss/tailwind.css'
import VueGtag from 'vue-gtag'

createApp(App).use(VueGtag, {
  property: {
    id: 'G-Z8XQ33JTE0'
  }
}).mount('#app')
