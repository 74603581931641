import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94ac0e08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pt-5 px-2 w-10/12 mx-4 event-card shadow-xl" }
const _hoisted_2 = { class: "text-2xl font-bold" }
const _hoisted_3 = { class: "font-medium" }
const _hoisted_4 = { class: "p-2 text-dark-gray-400 top-[150px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.getName), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getDescription), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getLastUpdate), 1)
  ]))
}