import { passInterface } from '@/interfaces/pass.interface'

export default {
  // eslint-disable-next-line
  async getMountainPass (): Promise<passInterface> {
    return await fetch(process.env.VUE_APP_API_URL)
      .then(function (response) {
        return response.json()
      }).then(function (data) {
        return data
      })
  }
}
