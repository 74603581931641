
import { defineComponent } from 'vue'

import PassService from '@/services/PassService'
import passCard from '@/components/passCard.vue'
import { passEntity } from '@/interfaces/passEntity.interface'
import { passInterface } from '@/interfaces/pass.interface'

export default defineComponent({
  name: 'EventList',
  components: {
    passCard
  },

  data () {
    return {
      mountainPasses: {} as passInterface
    }
  },
  async created () {
    try {
      this.mountainPasses = await PassService.getMountainPass()
    } catch (e) {
      console.log('Something went wrong')
    }
  },
  computed: {
    sortPassesByName ():passEntity[] | undefined {
      if (Object.keys(this.mountainPasses).length === 0) {
        return
      }
      const passes = this.mountainPasses.data.Entity
      const sortedPasses = passes.sort((a, b):number => {
        if (!a && !b) {
          return 0
        }

        return a.Name.localeCompare(b.Name)
      })
      return sortedPasses
    },

    getDate ():string {
      const date = new Date(this.mountainPasses.date)
      return date.toLocaleString()
    }
  }
})
